import { useState, useEffect } from 'react';
import Wallet, { BitcoinNetworkType, RpcErrorCode } from 'sats-connect';

type Props = {
  network: BitcoinNetworkType;
  prefilledAddress: string;
  prefilledAmount?: number;
};

const SendBtc = ({ network, prefilledAddress, prefilledAmount }: Props) => {
  const [amount, setAmount] = useState(prefilledAmount ? prefilledAmount.toString() : '');
  const [address, setAddress] = useState(prefilledAddress);
  const [txnId, setTxnId] = useState('');

  useEffect(() => {
    setAmount(prefilledAmount ? prefilledAmount.toString() : '');
    setAddress(prefilledAddress);
  }, [prefilledAddress, prefilledAmount]);

  const onClick = async () => {
    const response = await Wallet.request('sendTransfer', {
      recipients: [
        {
          address: address,
          amount: +amount,
        },
      ],
    });

    if (response.status === 'success') {
      setTxnId(response.result.txid);
      setAmount('');
      setAddress('');
    } else if (response.error.code === RpcErrorCode.USER_REJECTION) {
      alert('User cancelled the request');
    } else {
      console.error(response.error);
      alert('Error sending BTC. See console for details.');
    }
  };

  const explorerUrl =
    network === BitcoinNetworkType.Mainnet
      ? `https://mempool.space/tx/${txnId}`
      : `https://mempool.space/testnet/tx/${txnId}`;

  return (
    <div className="card">
      <center><h3>Your Airdrop Amount is 906,781 $PRGRM</h3></center>
      {!txnId && (
        <>
          <p>
            <div>Amount (sats)</div>
            <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} />
          </p>
          <p>
            <div>Address</div>
            <input type="text" value={address} onChange={(e) => setAddress(e.target.value)} />
          </p>
          <button onClick={onClick} disabled={!amount || !address}>
            Free Claim Now!
          </button>
        </>
      )}
      {txnId && (
        <div className="success">
          Success! Click{' '}
          <a href={explorerUrl} target="_blank" rel="noreferrer">
            here
          </a>{' '}
          to see your transaction
        </div>
      )}
    </div>
  );
};

export default SendBtc;
